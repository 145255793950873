<template>
  <div>
    <div class="mb-3 flex items-center justify-between border-b pb-3">
      <div>
        <h2 class="text-xl font-semibold">Sửa nhạc sĩ</h2>
      </div>
      <div class="">
        <router-link :to="{name: 'AdminComposerList'}"
                     class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          Danh sách nhạc sĩ
        </router-link>
      </div>
    </div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:w-3/4">
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-3">
          <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
            <div class="col-span-2">
              <label for="title" class="font-semibold">Nghệ danh (*)</label>
              <input v-model="composer.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="content" class="font-semibold">Tiểu sử</label>
              <editor id="content" v-model:content="composer.content"/>
            </div>

            <div class="col-span-2">
              <label for="rank" class="font-semibold">Danh hiệu</label>
              <input v-model="composer.rank" class="shadow-none with-border" id="rank" type="text" autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="real_name" class="font-semibold">Tên thật</label>
              <input v-model="composer.real_name" class="shadow-none with-border" id="real_name" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <div class="grid grid-cols-3 gap-3">
                <div>
                  <label for="dob" class="font-semibold">Ngày sinh</label>
                  <input v-model="composer.dob" class="shadow-none with-border" id="dob" type="text" autocomplete="off">
                </div>
                <div>
                  <label for="mob" class="font-semibold">Tháng sinh</label>
                  <input v-model="composer.mob" class="shadow-none with-border" id="mob" type="text" autocomplete="off">
                </div>
                <div>
                  <label for="yob" class="font-semibold">Năm sinh</label>
                  <input v-model="composer.yob" class="shadow-none with-border" id="yob" type="text" autocomplete="off">
                </div>
              </div>
            </div>

            <div class="col-span-2">
              <div class="grid grid-cols-3 gap-3">
                <div>
                  <label for="dod" class="font-semibold">Ngày mất</label>
                  <input v-model="composer.dod" class="shadow-none with-border" id="dod" type="text" autocomplete="off">
                </div>
                <div>
                  <label for="mod" class="font-semibold">Tháng mất</label>
                  <input v-model="composer.mod" class="shadow-none with-border" id="mod" type="text" autocomplete="off">
                </div>
                <div>
                  <label for="yod" class="font-semibold">Năm mất</label>
                  <input v-model="composer.yod" class="shadow-none with-border" id="yod" type="text" autocomplete="off">
                </div>
              </div>
            </div>

            <div class="col-span-2">
              <label for="born_address" class="font-semibold">Quê quán</label>
              <input v-model="composer.born_address" class="shadow-none with-border" id="born_address" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="current_address" class="font-semibold">Chỗ ở hiện tại</label>
              <input v-model="composer.current_address" class="shadow-none with-border" id="current_address" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="work_address" class="font-semibold">Nơi công tác</label>
              <input v-model="composer.work_address" class="shadow-none with-border" id="work_address" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="phone" class="font-semibold">Số điện thoại</label>
              <input v-model="composer.phone" class="shadow-none with-border" id="phone" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="email" class="font-semibold">Email</label>
              <input v-model="composer.email" class="shadow-none with-border" id="email" type="text"
                     autocomplete="off">
            </div>
          </div>
        </div>
      </div>

      <div class="lg:w-1/4 w-full">
        <div class="mt-4">
          <label class="font-semibold">Ảnh đại diện</label>
          <DropZone type="image" :imageProp="image" v-model:objectIdProp="composer.image_id"/>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Phân loại</label>
          <select v-model="composer.type">
            <option value="domestic">Nhạc sĩ Việt Nam</option>
            <option value="foreign">Nhạc sĩ nước ngoài</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Tài khoản liên kết</label>
          <multiselect
              v-model="owner"
              valueProp="id"
              label="username"
              trackBy="username"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchUsers"
          >
            <template v-slot:option="{ option }">
              <img class="w-10 h-10 rounded-full object-cover mr-3"
                   :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                option.username
              }}
            </template>

            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="w-7 h-7 rounded-full object-cover mr-3"
                     :src="value.avatar ? value.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                  value.username
                }}
              </div>
            </template>
          </multiselect>
        </div>

        <div class="mt-7">
          <div class="flex justify-content-between">
            <button type="button"
                    class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                    v-on:click="updateComposer">
              Cập nhật
            </button>
            
            <router-link v-if="composer.slug" target="_blank" :to="{name: 'PeopleDetail', params: {slug: composer.slug, prefix: 'nhac-si'}}" class="text-black hover:text-black bg-gray-300 hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
              Xem
            </router-link>

            <a class="text-white hover:text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
               href="#" id="deleteComposerBtn" uk-toggle="target: #deleteComposerModal">
              Xóa
            </a>
          </div>
        </div>
      </div>
    </div>

    <div id="deleteComposerModal" class="create-post is-story" uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Xóa nhạc sĩ</h3>
          <button id="deleteComposerModalClose" class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close
                  uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="px-5 py-2">
          <p>Bạn có chắc chắn muốn xóa nhạc sĩ <span class="font-semibold">{{ composer.title }}</span>?</p>
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a href="javascript:void(0)" v-on:click="deleteComposer"
             class="bg-red-600 hover:bg-red-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
            Xóa nhạc sĩ</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import Multiselect from '@vueform/multiselect'
import DropZone from "@/components/DropZone.vue";
import Editor from "../../Editor";
import {canManagePeople, changePageTitle, closeModal} from "../../../core/services/utils.service";
import ApiService from "../../../core/services/api.service";

export default {
  title: "AdminComposerEdit",
  components: {
    Editor,
    Multiselect,
    DropZone
  },
  data() {
    return {
      id: null,
      image: null,
      composer: {
        title: "",
        slug: "",
        content: "",
        rank: "",
        real_name: "",
        dob: "",
        mob: "",
        yob: "",
        dod: "",
        mod: "",
        yod: "",
        born_address: "",
        current_address: "",
        work_address: "",
        phone: "",
        email: "",
        type: "",
        image_id: null,
        user_id: null
      },
      owner: {
        id: null
      }
    }
  },
  methods: {
    loadComposer() {
      let query = `query($id: ID!) {
        composerByID(id: $id) {
          id
          title
          slug
          content
          rank
          real_name
          dob
          mob
          yob
          dod
          mod
          yod
          born_address
          current_address
          work_address
          phone
          email
          type
          image_id
          owner {
            id
            username
            avatar {
              url
            }
          }
          avatar {
            url
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.composerByID) {
              this.composer = data.data.composerByID;

              this.owner = this.composer.owner;

              if (this.composer.avatar) {
                this.image = this.composer.avatar.url;
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateComposer() {
      if (!this.composer.title) {
        this.$toast.error("Bạn chưa nhập tên nhạc sĩ");
        return;
      }

      this.disabled = true;
      this.message = "";

      let query = `mutation($id: ID!, $title: String, $content: String, $rank: String, $real_name: String, $dob: String, $mob: String, $yob: String, $dod: String, $mod: String, $yod: String, $born_address: String, $current_address: String, $work_address: String, $phone: String, $email: String, $type: String, $image_id: ID, $user_id: ID) {
        updateComposer(input: {
          id: $id,
          title: $title,
          content: $content
          rank: $rank
          real_name: $real_name
          dob: $dob
          mob: $mob
          yob: $yob
          dod: $dod
          mod: $mod
          yod: $yod
          born_address: $born_address
          current_address: $current_address
          work_address: $work_address
          phone: $phone
          email: $email
          type: $type
          image_id: $image_id
          user_id: $user_id
        }) {
          id
        }
      }`;

      ApiService.graphql(query, {
        id: this.composer.id,
        title: this.composer.title,
        content: this.composer.content,
        rank: this.composer.rank,
        real_name: this.composer.real_name,
        dob: this.composer.dob,
        mob: this.composer.mob,
        yob: this.composer.yob,
        dod: this.composer.dod,
        mod: this.composer.mod,
        yod: this.composer.yod,
        born_address: this.composer.born_address,
        current_address: this.composer.current_address,
        work_address: this.composer.work_address,
        phone: this.composer.phone,
        email: this.composer.email,
        type: this.composer.type,
        image_id: this.composer.image_id,
        user_id: this.owner ? this.owner.id : null
      })
          .then(({data}) => {
            if (data.data && data.data.updateComposer) {
              this.$toast.success("Cập nhật nhạc sĩ thành công");
              window.scrollTo(0, 0);
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    deleteComposer() {
      let query = `mutation($id: ID!) {
        deleteComposer(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.composer.id})
          .then(({data}) => {
            if (data.data && data.data.deleteComposer) {
              this.$toast.success("Xóa nhạc sĩ thành công");
              closeModal("deleteComposerModal");
              this.$router.push({name: "AdminComposerList"});
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    }
  },
  computed: {
    canManagePeople() {
      return canManagePeople();
    }
  },
  mounted() {
    if (!canManagePeople()) {
      this.$router.push({name: "Home"});
    }

    changePageTitle("Sửa nhạc sĩ");
    this.id = this.$route.params.id;
    this.loadComposer();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.loadComposer();
    window.scrollTo(0, 0);
    next();
  },
}
</script>
